import styled from "styled-components";

export default styled.div`
	min-height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 20px 20px 90px 20px;
	.inactive {
		text-align: center;
		color: #fff;
		font-size: 1.5rem;
		a {
			color: #fff;
		}
	}
	.product {
		position: relative;
		box-sizing: border-box;
		border-radius: 0 6px 6px 0;
		font-size: 14px;
		padding: 0;
		margin: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		p {
			margin-bottom: 0;
		}
		h1 {
			text-align: center;
			font-weight: 900;
			margin-bottom: 0;
		}
		h2 {
			display: block;
			margin: 10px 0 0 0;
			font-size: 18px;
			color: rgba(0, 0, 0, 0.8);
		}

		.price {
			display: flex;
			flex-direction: row;
			line-height: 1;
			align-items: flex-end;
			font-weight: 300;
			.ammount {
				font-size: 33px;
				margin-right: 10px;
			}
			.term {
				font-size: 12px;
				margin-bottom: 3px;
			}
		}
	}
	.form {
		position: relative;
		width: 39vw;
		max-width: 600px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.logo {
		max-width: 250px;
		margin: 0 auto 20px auto;
	}
	.form-content {
		background: #ffffff;
		box-sizing: border-box;
		padding: 2rem;
		border-radius: 6px;
		font-size: 14px;
		display: flex;
		flex-direction: column;
		box-shadow: 0 -1px 10px rgb(0 0 0 / 5%), 0 1px 4px rgb(0 0 0 / 10%),
			0 10px 30px rgba(0, 0, 0, 0.1);
	}
	.error {
		color: red;
	}
	label {
		display: block;
		margin-top: 1rem;
		font-size: 16px;
		font-weight: 900;
	}
	input {
		border: none;
		border-radius: 0.2rem;
		background-color: rgba(0, 0, 0, 0.05);
		padding: 0.7rem;
		width: 100%;
		font-size: 16px;
		box-sizing: border-box;
		border: 1px solid rgba(0, 0, 0, 0.1);
	}
	section {
		margin: 1rem 0;
		max-width: 100%;
	}
	h4 {
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		color: rgba(0, 0, 0, 0.3);
		text-transform: uppercase;
		text-align: center;
		position: relative;
		margin: 10px 0 30px 0;
		span {
			position: relative;
			background-color: #fff;
			bottom: -11px;
			padding: 5px 20px;
		}
	}
	.submit {
		box-sizing: border-box;
		background-color: #0542a1;
		color: #fff;
		font-weight: 900;
		font-size: 20px;
		padding: 1rem 2rem;
		width: 100%;
		max-width: 100%;
		border-radius: 6px;
	}
`;
