import React from "react";

import CanceledStyled from "./Canceled.styled.js";

const Canceled = () => (
	<CanceledStyled>
		{" "}
		<div class="sr-main">
			<header className="sr-header">
				<div className="sr-header__logo"></div>
			</header>
			<div className="sr-payment-summary completed-view">
				<h1>Your payment was canceled</h1>
				<button onclick="window.location.href = '/';">Restart demo</button>
			</div>
		</div>
	</CanceledStyled>
);

Canceled.displayName = "Canceled";

export default Canceled;
