import React from 'react';
import { Routes, Route } from "react-router-dom";

import SignupForm from "./SignupForm";
import Canceled from "./Canceled";
import Success from "./Success";

import AppStyled from './App.styled.js';

function App() {
  return (
    <AppStyled className="App">
      <Routes>
        <Route path="/" element={<SignupForm />}>
          <Route index element={<SignupForm />} />
          <Route path=":product" element={<SignupForm />} />
          <Route path=":product/:rep" element={<SignupForm />} />
        </Route>
        <Route path="canceled" element={<Canceled />} />
        <Route path="success" element={<Success />} />
      </Routes>
    </AppStyled>
  );
}

export default App;
