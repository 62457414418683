import React from "react";

import SuccessStyled from "./Success.styled";

const Success = () => (
	<SuccessStyled>
		<div className="sr-main">
			<header className="sr-header">
				<div className="sr-header__logo"></div>
			</header>

			<div className="sr-payment-summary completed-view">
				<h1>Your payment succeeded</h1>
				<h4>View CheckoutSession response:</h4>
			</div>
			<div className="sr-section completed-view">
				<div className="sr-callout">
					<pre></pre>
				</div>
				<button onclick="window.location.href = '/';">Restart demo</button>
			</div>
		</div>
	</SuccessStyled>
);

Success.displayName = "Success";

export default Success;
