import React from "react";
import { useForm } from "react-hook-form";
import Autocomplete from "react-google-autocomplete";
import { useParams, useSearchParams } from "react-router-dom";

import SignupFormStyled from "./SignupForm.styled.js";
import { ReactComponent as Logo } from "../sardiusLogo.svg";
const mapsApiKey = "AIzaSyDgBmDVqJ05i-7yeAuvm0jo5hvknsTNMJg";
const apiUrl = process.env.REACT_APP_APP_URL;

const SignupForm = () => {
	const [searchParams] = useSearchParams();
	const params = useParams();
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm();
	const [loaded, setLoaded] = React.useState(false);
	const [productInfo, setProductInfo] = React.useState();

	const onSubmit = (data) => {
		fetch(`${apiUrl}/accounts/payments/checkout`, {
			method: "post",
			body: JSON.stringify(data),
		})
			.then((response) => {
				return response.json();
			})
			.then((responseData) => {
				console.log("Success:", responseData);
				console.log(responseData.url);
				if (responseData.url) {
					window.open(responseData.url);
				}
			});
	};
	React.useEffect(() => {
		const priceId = params.product;
		fetch(`${apiUrl}/accounts/payments/product/${priceId}`)
			.then((response) => {
				setLoaded(true);
				return response.json();
			})
			.then((responseData) => {
				if (responseData && responseData.product.name) {
					setProductInfo(responseData);
				}
			});
		setValue("price_id", priceId);
		setValue("deal_id", searchParams.get("d"));
		setValue("sales_id", searchParams.get("ref"));
		setValue("referal_id", params.rep || searchParams.get("rep"));
	}, [params, searchParams, setValue]);

	const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

	return (
		<SignupFormStyled>
			{!productInfo?.price?.active && !productInfo?.product?.active ? (
				<div className="inactive">
					{loaded ? (
						<div>
							<h1>Oops. That page doesn't exist.</h1>
							<p>
								The page you are looking for doesn't exist or has been moved.
							</p>
							<a href="mailto:sales@sardius.media">sales@sardius.media</a>
						</div>
					) : (
						<div>
							<h1>Loading...</h1>
						</div>
					)}
				</div>
			) : (
				<form className="form" onSubmit={handleSubmit(onSubmit)}>
					<Logo className="logo" />
					<div className="form-content">
						<div className="product">
							<h1>{productInfo?.product?.name}</h1>
							<p>{productInfo?.product?.description}</p>
						</div>
						<section>
							<div>
								<div className="form-group">
									<label for="name" className="form-label">
										Your Name <span className="error">*</span>
									</label>
									<input id="name" {...register("name", { required: true })} />
									{errors.name && (
										<span className="error">This field is required</span>
									)}
								</div>
								<div className="form-group">
									<label for="email" className="form-label">
										Your Email <span className="error">*</span>
									</label>
									<input
										id="email"
										type="email"
										{...register("email", {
											required: true,
											pattern: /^\S+@\S+$/i,
										})}
									/>
									{errors.email && (
										<span className="error">A valid email is required</span>
									)}
								</div>
								<div className="form-group">
									<label for="title" className="form-label">
										Your Title
									</label>
									<input defaultValue="" id="title" {...register("title")} />
								</div>

								<div className="form-group">
									<label for="phone" className="form-label">
										Phone
									</label>
									<input type="tel" id="phone" {...register("phone")} />
								</div>
								<h4>
									<span>Organization Info</span>
								</h4>
								<div className="form-group">
									<label for="org_name" className="form-label">
										Organization Name <span className="error">*</span>
									</label>
									<input
										id="org_name"
										{...register("org_name", { required: true })}
									/>
									{errors.org_name && (
										<span className="error">This field is required</span>
									)}
								</div>

								<div className="form-group">
									<label for="org_add" className="form-label">
										Address <span className="error">*</span>
									</label>
									<Autocomplete
										id="org_add"
										apiKey={mapsApiKey}
										options={{
											types: null,
											fields: [
												"geometry.location.lat",
												"geometry.location.lng",
												"place_id",
												"address_component",
												"formatted_address",
											],
											componentRestrictions: { country: "us" },
										}}
										onPlaceSelected={(place) => {
											place.address_components.map((item) => {
												if (item.types.includes("postal_code"))
													setValue("org_zipcode", item.long_name);
												if (item.types.includes("locality"))
													setValue("org_city", item.long_name);
												if (item.types.includes("administrative_area_level_1"))
													setValue("org_state", item.long_name);
												if (item.types.includes("country"))
													setValue("org_country", item.short_name);
											});
											setValue(
												"org_address",
												place.formatted_address.split(",").length > 0
													? place.formatted_address.split(",")[0]
													: place.formatted_address
											);
											console.log(
												place?.formatted_address.split(",").length > 0
													? place.formatted_address.split(",")[0]
													: place.formatted_address
											);
											setValue(
												"org_address_formatted",
												place.formatted_address
											);
											setValue("org_geo_lat", place.geometry.location.lat());
											setValue("org_geo_lng", place.geometry.location.lng());
										}}
									/>
									{errors.org_address && (
										<span className="error">This field is required</span>
									)}
								</div>
								<div className="form-group">
									<label for="org_website" className="form-label">
										Website
									</label>
									<input id="org_website" {...register("org_website")} />
								</div>
							</div>
						</section>
						<section>
							<input
								type="hidden"
								id="org_address_formatted"
								{...register("org_address_formatted")}
							/>
							<input
								type="hidden"
								id="org_address"
								{...register("org_address")}
							/>
							<input
								type="hidden"
								id="org_geo_lat"
								{...register("org_geo_lat")}
							/>
							<input
								type="hidden"
								id="org_geo_lng"
								{...register("org_geo_lng")}
							/>
							<input
								type="hidden"
								id="org_zipcode"
								{...register("org_zipcode")}
							/>
							<input type="hidden" id="org_city" {...register("org_city")} />
							<input type="hidden" id="org_state" {...register("org_state")} />
							<input
								type="hidden"
								id="org_country"
								{...register("org_country")}
							/>
							<input type="hidden" id="deal_id" {...register("deal_id")} />
							<input type="hidden" id="price_id" {...register("price_id")} />
							<input type="hidden" id="sales_id" {...register("sales_id")} />
							<input
								type="hidden"
								id="referal_id"
								{...register("referal_id")}
							/>
							<input
								type="hidden"
								defaultValue={timezone}
								id="org_timezone"
								{...register("org_timezone")}
							/>
							<input value="Checkout" type="submit" className="submit" />
						</section>
					</div>
				</form>
			)}
		</SignupFormStyled>
	);
};

SignupForm.displayName = "SignupForm";

export default SignupForm;
